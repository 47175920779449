<template>
  <app-modal :title="title" @submit="onSubmit">
    <div class="flex gap-4">
      <app-form-input-text v-model="form.name" label="Name" required />
      <v-switch
        v-model="form.is_company_office"
        color="primary"
        label="Company office"
      />
    </div>
    <app-address-autocomplete v-model="form.address" label="Address" required />

    <v-list
      v-model:selected="selectedFacilities"
      color="primary"
      select-strategy="classic"
    >
      <v-list-item
        v-for="facility in facilities"
        :key="facility.value"
        :title="facility.label"
        :value="facility.value"
      >
        <template #prepend="{ isActive }">
          <v-checkbox density="compact" hide-details :model-value="isActive" />
        </template>
      </v-list-item>
    </v-list>

    <app-form-textarea
      v-model="form.information"
      label="Additional information"
    />
  </app-modal>
</template>

<script lang="ts" setup>
const properties = defineProps<{
  place?: DatabaseTable<"places">;
}>();

const title = computed(() =>
  properties.place ? "Edit place" : "Create a new place",
);

const form = reactive<DatabaseInsertRequest<"places">>({
  name: properties.place?.name ?? "",
  address: properties.place
    ?.address as unknown as DatabaseCompositeType<"address">,
  facilities: properties.place?.facilities ?? {
    stairs: false,
    elevator: false,
    wheelchair_accessible: false,
  },
  information: properties.place?.information,
  is_company_office: properties.place?.is_company_office ?? false,
});

const { close } = useModal("placeEditionModal");

const onSubmit = () => close({ payload: form, confirmed: true });

const facilities: {
  label: string;
  value: keyof DatabaseCompositeType<"place_facilities">;
}[] = [
  {
    label: "Has stairs",
    value: "stairs",
  },
  {
    label: "Has elevator",
    value: "elevator",
  },
  {
    label: "Is wheelchair accessible",
    value: "wheelchair_accessible",
  },
];

const selectedFacilities = computed({
  get() {
    return Object.keys(form.facilities ?? {}).filter((key) => {
      if (!form.facilities) return false;

      return form.facilities[key as keyof typeof form.facilities] === true;
    });
  },
  set(values) {
    if (!form.facilities) return;

    form.facilities = Object.keys(form.facilities ?? {}).reduce(
      (facilities, key) => {
        facilities[key as keyof typeof form.facilities] = values.includes(key);

        return facilities;
      },
      {} as typeof form.facilities,
    );
  },
});
</script>
