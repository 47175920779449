<template>
  <app-form-input-text
    ref="input"
    :invalid="!!errorMessage"
    :model-value="model?.label"
  >
    <template #append-inner>
      <app-icon
        v-if="model"
        class="cursor-pointer"
        icon="ph:x"
        @click="model = null"
      />
    </template>
  </app-form-input-text>
</template>

<script lang="ts" setup>
import { useField } from "vee-validate";

import { useGooglePlacesAutocomplete } from "#imports";

const properties = defineProps<{
  modelValue?: DatabaseCompositeType<"address"> | null;
  required?: boolean;
}>();

const { value: model, errorMessage } = useField(
  "address",
  {
    required: properties.required,
  },
  {
    initialValue: properties.modelValue,
  },
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const input = templateRef<any>("input");

useGooglePlacesAutocomplete(
  computed(() => input.value?.$el.querySelector("input")),
  {
    onSelect: (address) => {
      model.value = address;
    },
  },
);
</script>
